import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/Auth/auth.service';
import { LoginService } from '../services/auth/login.service';
import { TimeSheetService } from '../services/time-sheet.service';
import { BaseComponent } from '../shared/base.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector,
    private loginService: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private timeSheetService: TimeSheetService,
    private toastr: ToastrService) {
    super(injector);

    localStorage.setItem('time', '0');
  }

  ngOnInit(): void {
    var token = this.authService.getToken();
    if (token != null) {
      this.router.navigate(['dashboard']);
    }
  }

  userName: string;
  password: string;

  login() {
    this.spinner.show();

    this.loginService.login(this.userName, this.password).subscribe({
      next: (result: any) => {
        localStorage.setItem('time', '0');

        this.authService.setUserInformation(result?.token, result?.userId,
          result?.employeeId, result?.clientId, result?.userName, result?.imageName);

        if (!this.authService.hasAccess([{ claimType: "CRMS", claimValue: "AccessCrmsSystem" }])) {
          this.toastr.error("You are not authorized to access the sysytem, Contact System Administrator");
          localStorage.removeItem('token');
          this.spinner.hide();
          return;
        }

        if (result?.employeeId != null) {
          this.logTimeSheet(result?.employeeId)
        }

        if (result?.defaultPasswordChanged) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['change-password']);
        }

        this.spinner.hide();
      },
      error: (error) => {
        if (error?.status == 403) {
          this.toastr.error('Forbidden', 'Oops :(');
          this.spinner.hide();
          return;
        }
        var message = error.error.message;
        if (!message)
          message = "Unknown error occurred, Contact The System Administrator";

        this.toastr.error(message, 'Oops :(');
        this.spinner.hide();
      }
    });
  }

  logTimeSheet(employeeId) {
    this.timeSheetService.post(employeeId, 0).subscribe({
      next: (timeSheetId: any) => {
        localStorage.setItem('timeSheetId', timeSheetId);
      },
      error: (error) => {
        if (error?.status == 403) {
          this.toastr.error('Forbidden', 'Oops :(');
          return;
        }
        var message = error.error.message;
        if (!message)
          message = "Unknown error occurred, Contact The System Administrator";

        this.toastr.error(message, 'Oops :(');
      }
    });
  }

}
