import { Injectable, Injector } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(private injector: Injector) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        var router = this.injector.get(Router);
        var toastr = this.injector.get(ToastrService);

        const expectedClaims = route.data['expectedClaims'];

        if (!this.isAuthenticated()) {
            router.navigate(['login']);
            return false;
        }

        if (!expectedClaims)
            return true;

        if (!this.hasAccess(expectedClaims)) {
            toastr.error('You are not authorized, Contact The System Administrator', 'Access Denied');
            return false;
        }
        return true;
    }

    public isAuthenticated(): boolean {
        var toastr = this.injector.get(ToastrService);
        const token = localStorage.getItem('token');

        if (token == null) return false;


        let jwtHelper: JwtHelperService = new JwtHelperService();
        if (jwtHelper.isTokenExpired(token)) {
            this.logout();
            toastr.error(
                'Session Expired',
                'Your session expired, please login again'
            );
            return false;
        }

        return true;
    }


    public hasAccess(allowedClaims: any): boolean {
        if (allowedClaims == null) return true;
        var hasAccess = false;

        const token = localStorage.getItem('token');

        if (token == null) return false;

        const tokenPayload: any = decode(token);
        var userClaims: any = null;
        allowedClaims
            .map((e: any) => e.type)
            .forEach((type: any) => {
                if (userClaims == null && tokenPayload[type] != null) {
                    userClaims = tokenPayload[type];
                }
            });

        if (userClaims == null) return hasAccess;

        if (typeof userClaims === 'string') {
            userClaims = [userClaims];
        }

        allowedClaims
            .map((e: any) => e.value)
            .forEach((value: any) => {
                if (userClaims.indexOf(value) != -1) {
                    hasAccess = true;
                }
            });

        return hasAccess;
    }

    logout() {

    }
}