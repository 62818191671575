import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/auth/user.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder) { super(injector); }

  changePasswordForm!: FormGroup;

  ngOnInit(): void {
    var userInfo = this.AuthService.getUserInformation();

    this.changePasswordForm = this.fb.group({
      'applicationUserId': [userInfo.userId],
      'oldPassword': ['', Validators.required],
      'newPassword': ['', [Validators.required, Validators.minLength(6)]],
      'confirmPassword': ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: PasswordValidators.matchPassword
    });
  }

  get oldPassword() {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  changePassword() {
    if (!this.changePasswordForm.valid) {
      this.toastr.error('invalid form');
      return;
    }

    this.userService.resetPassword(this.changePasswordForm.value).subscribe({
      next: (result) => {
        this.toastr.success("Your password changed");
        this.spinner.hide();

        this.router.navigate(['/dashboard']);
      },
      error: (error) => {
        if (error?.status == 403) {
          this.toastr.error('Forbidden', 'Oops :(');
          this.spinner.hide();
          return;
        }
        var message = error.error.message;
        if (!message)
          message = "Unknown error occurred, Contact The System Administrator";

        this.toastr.error(message, 'Oops :(');
        this.spinner.hide();
      }
    });
  }
}

export class PasswordValidators {
  static matchPassword(control: AbstractControl) {
    var newPwd2 = control.get('newPassword');
    var confirmPwd2 = control.get('confirmPassword');

    if (newPwd2?.value !== confirmPwd2?.value) {
      return { passwordIsNotMatched: true };
    }

    return null;
  }
}