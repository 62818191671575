<div class="main-wrapper container mt-5">
    <div class="account-box">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-center">Change password</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <form [formGroup]="changePasswordForm">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="col-form-label">Old password</label>
                                    <input type="password" class="form-control" name="oldPassword"
                                        formControlName="oldPassword" placeholder="Old password">
                                    <div *ngIf="oldPassword?.touched && oldPassword?.invalid">
                                        <p class="text-danger" *ngIf="oldPassword?.errors?.['required']">
                                            {{ "Field required" | translate }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label class="col-form-label">New Password</label>
                                    <input type="password" class="form-control" name="newPassword"
                                        formControlName="newPassword" placeholder="New Password">
                                    <div *ngIf="newPassword?.touched && newPassword?.invalid">
                                        <p class="text-danger" *ngIf="newPassword?.errors?.['required']">
                                            {{ "Field required" | translate }}
                                        </p>
                                        <p class="text-danger" *ngIf="newPassword?.errors?.['minlength']">
                                            {{ "The password should be at least 6 characters" | translate }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label class="col-form-label">Confirm New Password</label>
                                    <input type="password" class="form-control" name="confirmPassword"
                                        formControlName="confirmPassword" placeholder="Confirm Password">
                                    <div *ngIf="confirmPassword?.touched">
                                        <p class="text-danger" *ngIf="confirmPassword?.errors?.['required']">
                                            {{ "Field required" | translate }}
                                        </p>
                                        <p class="text-danger"
                                            *ngIf="changePasswordForm?.errors?.['passwordIsNotMatched']">
                                            {{ "Your password is not matched" | translate }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="submit" [routerLink]="['/dashboard']">Skip</button>
                <button class="btn btn-primary" type="submit" (click)="changePassword()">Confirm</button>
            </div>
        </div>
    </div>
</div>