import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/shared/base.component';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseComponent {
  constructor(injector: Injector, private httpClient: HttpClient) {
    super(injector);
  }

  login(userName: string, password: string) {
    let req = {
      email: '',
      userName: userName,
      password: password,
    }
    return this.httpClient.post(this.pathUrl + "/Auth/Login", req);
  }

  register(email: string, userName: string, password: string) {
    let req = {
      email: email,
      userName: userName,
      password: password,
    }
    return this.httpClient.post(this.pathUrl + "/Auth/Register", req);
  }
}
