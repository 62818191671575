<div class="mt-5 print-footer">
    <hr>
    <div class="row">
        <div class="col-4">
            <h6 class="invoice-name">{{invoice?.companyName}}</h6>
            <p>
                {{invoice?.invoiceState}}, {{invoice?.invoicePostalCode}} <br>
                {{invoice?.invoiceCity}}, <br>
                {{invoice?.invoiceCountry}} <br>
            </p>
        </div>
        <div class="col-4 text-center">
            <p class="invoice-details invoice-details-two">
                Account Number : <span class="text-primary">{{invoice?.accountNumber}}</span>
                <br>
                IFSC Code : <span class="text-primary">{{invoice?.code}}</span> <br>
                Bank Name : <span class="text-primary">{{invoice?.bankName}}</span> <br>
                Account Holder Name : <span class="text-primary">{{invoice?.accountHolderName}}</span>
                <br>
            </p>
        </div>
        <div class="col-4 text-end">
            <p [innerHTML]="company?.invoiceFooterThirdSection"></p>
        </div>
    </div>
</div>