<!-- Main Wrapper -->
<div class="main-wrapper">
    <div class="account-content">

        <div class="container">

            <!-- Account Logo -->
            <div class="account-logo">
                <a routerLink="/dashboard"><img src="assets/img/logo.png" alt="Dreamguy's Technologies"></a>
            </div>
            <!-- /Account Logo -->

            <div class="account-box">
                <div class="account-wrapper">
                    <h3 class="account-title">Login</h3>

                    <!-- Account Form -->
                    <div>
                        <div class="form-group">
                            <label>User name</label>
                            <input class="form-control" type="text"  [(ngModel)]="userName">
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label>Password</label>
                                </div>
                                <!-- <div class="col-auto">
                                    <a class="text-muted" routerLink="/forgot-password">
                                        Forgot password?
                                    </a>
                                </div> -->
                            </div>
                            <input class="form-control" type="password" [(ngModel)]="password">
                        </div>
                        <div class="form-group text-center">
                            <button class="btn btn-primary account-btn" (click)="login()" type="submit">Login</button>
                        </div>
                        <!-- <div class="account-footer">
                            <p>Don't have an account yet? <a routerLink="/register">Register</a></p>
                        </div> -->
                    </div>
                    <!-- /Account Form -->

                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Main Wrapper -->