import { Injectable, Injector } from "@angular/core";
import { BaseComponent } from "../shared/base.component";

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends BaseComponent {
    constructor(injector: Injector) {
        super(injector);
    }

    get() {
        return this.HttpClient.get<any>(this.pathUrl + `/Companies`);
    }

    put(company: any) {
        return this.HttpClient.put<any>(this.pathUrl + `/Companies`, company);
    }

    updateBankInfo(bank: any) {
        return this.HttpClient.put<any>(this.pathUrl + `/Companies/UpdateBankInfo`, bank);
    }

    updateInvoiceInfo(bank: any) {
        return this.HttpClient.put<any>(this.pathUrl + `/Companies/UpdateInvoiceInfo`, bank);
    }
}
