import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationMiddlewareService } from './core/notification-middleware.service';

declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'template';

  constructor(private translate: TranslateService,
    private notificationMiddleware: NotificationMiddlewareService) {
    var lang = localStorage.getItem('lang') ?? 'en';
    translate.setDefaultLang(lang);
    translate.use(lang);
  }

  ngOnInit(): void {
    this.notificationMiddleware.init();
  }
}
