import { DatePipe } from '@angular/common';
import { Component, ElementRef, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/client.service copy';
import { invoicesService } from 'src/app/services/invoices.service';
import { BaseComponent } from 'src/app/shared/base.component';

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvoicePrintComponent extends BaseComponent implements OnInit {
  constructor(injector: Injector,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private invoicesService: invoicesService,
    private companyService: CompanyService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute) {
    super(injector);
    this.invoiceId = this.route.snapshot.params['id'];
  }

  invoiceId: any;
  invoice: any;
  company: any;

  printContent: any[] = [];

  pageContent = '';

  ngOnInit(): void {
    this.spinner.show();

    this.companyService.get().subscribe({
      next: (company) => {
        this.company = company;

        this.invoicesService.getInvoice(this.invoiceId).subscribe({
          next: (result) => {
            var pageStarter = `<div><div class="card"><div class="card-body"><div class="invoice-item invoice-item-one ms-5 me-5" id="content">`;
            var pageCloser = `</div></div></div></div>`;

            var header = `<div><div class="row"><div class="col-md-6 offset-md-6 text-right mb-5" style="flex: 0 0 auto; width: 50%; margin-left: 50%"><img src="${this.logoUrl}1.png" alt="logo"></div><hr></div><div class="row"><div class="col-md-6 text-left"><h6 class="invoice-name">${result?.companyName}</h6><p>${result?.invoiceState}, ${result?.invoicePostalCode} ${result?.invoiceCity}, <br>${result?.invoiceCountry} <br></p></div></div><div class="row"><div class="col-md-4 offset-md-8 mb-3" style="text-align: left; margin-left: 66.6%"><h6 class="invoice-name">${result?.client}</h6><p class="invoice-details invoice-details-two">${result?.clientState}, ${result?.clientPostal} <br>${result?.clientCity} <br>${result?.clientCountry} <br></p></div></div><div class="row"><div class="col-md-4 offset-md-8 text-right mb-5" style="text-align: left; margin-left: 66.6%"><p class="invoice-details invoice-details-two">Service Period : ${result?.year},  ${this.getMonthName(result?.month)} <br>Date : ${this.datePipe.transform(result?.createdDate, 'dd/MM/yyyy')} <br>Due Date : ${this.datePipe.transform(result?.dueDate, 'dd/MM/yyyy')} <br>Invoice Number : ${result?.id} <br></p></div></div>`;
            var invoiceTitle = `<div class="col-12 mb-5"><h4 class="mb-4">${company?.invoiceTitle}</h4><span class="mb-3">${company?.invoiceHeader}</span>`;
            var headerCloser = `</div></div>`;
            var table = `<div class="invoice-item invoice-table-wrap"><div class="row"><div class="col-md-12"><div class="table-responsive"><table class="invoice-table table table-center mb-0" id="leads-table"><thead><tr><th>Lead Id</th><th>Quality</th><th>Lead Date</th><th>Amount</th></tr></thead><tbody>`;
            var tableCloser = `</tbody></table></div></div></div></div>`;
            var invoiceFooter = `<div class="col-12 mb-5 mt-5"><span class="mb-3">${company?.invoiceFooter}</span></div>`;

            this.invoice = result;
            var index = 0;
            var pageHeight = 1380;
            var currentHeight = 0;
            var totalPages = 0;

            var myElement = document.createElement('div');
            result?.invoiceDetail.forEach(element => {
              if (index == 0) {
                this.pageContent += pageStarter + header + invoiceTitle + headerCloser + table;
                myElement.innerHTML = this.pageContent;
                document.body.appendChild(myElement);
                currentHeight = myElement.offsetHeight - (totalPages * 2100);
              }

              var record = `<tr class="add-row"><td><p>leadId</p></td><td><p>quality</p></td><td><p>date</p></td><td><p>amount</p></td></tr>`;
              record = record.replace('leadId', element.leadId);
              record = record.replace('quality', element.quality);
              record = record.replace('date', `${this.datePipe.transform(element.date, 'dd/MM/yyyy')}`);
              record = record.replace('amount', result.currency + ' ' + element.amount);
              this.pageContent += record;

              myElement.innerHTML = this.pageContent;
              document.body.appendChild(myElement);
              currentHeight = myElement.offsetHeight - (totalPages * 2100);
              index++;

              if (currentHeight > pageHeight) {
                totalPages++;
                this.pageContent += tableCloser + pageCloser + `<br><br><br><br><br><br><br>`;
                myElement.innerHTML = this.pageContent;
                document.body.appendChild(myElement);

                this.pageContent += pageStarter + header + headerCloser + table;
                myElement.innerHTML = this.pageContent;
                document.body.appendChild(myElement);
                currentHeight = myElement.offsetHeight - (totalPages * 2100);
              }
            });

            this.pageContent += tableCloser;
            if (result?.invoiceContractedDetails.length == 0) {
              this.pageContent += pageCloser;
            }
            myElement.innerHTML = this.pageContent;
            document.body.appendChild(myElement);
            currentHeight = myElement.offsetHeight - (totalPages * 2100);


            var contractedLeadTableStarter = `<div class="row"><h4 class="ms-2 mt-4 text-primary">Contracted Leads</h4><div class="col-md-12"><div class="table-responsive"><table class="invoice-table table table-center mb-0"><thead><tr><th>Lead Id</th><th>Contracted date</th><th>Amount</th></tr></thead><tbody>`;
            var contractedLeadTableCloser = `</tbody></table></div></div></div>`;

            if (result?.invoiceContractedDetails.length > 0) {
              this.pageContent += contractedLeadTableStarter;
              myElement.innerHTML = this.pageContent;
              document.body.appendChild(myElement);
              currentHeight = myElement.offsetHeight - (totalPages * 2100);

              result?.invoiceContractedDetails.forEach(element => {
                var record = `<tr class="add-row"><td><p>leadId</p></td><td><p>date</p></td><td><p>amount</p></td></tr>`;
                record = record.replace('leadId', element.leadId);
                record = record.replace('date', `${this.datePipe.transform(element.contractedDate, 'dd/MM/yyyy')}`);
                record = record.replace('amount', result.currency + ' ' + element.amount);
                this.pageContent += record;

                myElement.innerHTML = this.pageContent;
                document.body.appendChild(myElement);
                currentHeight = myElement.offsetHeight - (totalPages * 2100);

                if (currentHeight > pageHeight) {
                  totalPages++;
                  this.pageContent += tableCloser + pageCloser + `<br><br><br><br><br><br>`;
                  myElement.innerHTML = this.pageContent;
                  document.body.appendChild(myElement);

                  this.pageContent += pageStarter + header + table;
                  myElement.innerHTML = this.pageContent;
                  document.body.appendChild(myElement);
                  currentHeight = myElement.offsetHeight - (totalPages * 2100);
                }
              });

              if (currentHeight > pageHeight) {
                totalPages++;
                this.pageContent += tableCloser + pageCloser + `<br><br><br><br><br><br>`;
                myElement.innerHTML = this.pageContent;
                document.body.appendChild(myElement);

                this.pageContent += pageStarter + header + table;
                myElement.innerHTML = this.pageContent;
                document.body.appendChild(myElement);
                currentHeight = myElement.offsetHeight - (totalPages * 2100);
              }

              this.pageContent += contractedLeadTableCloser;
              myElement.innerHTML = this.pageContent;
              document.body.appendChild(myElement);
              currentHeight = myElement.offsetHeight - (totalPages * 2100);
            }

            var tableFooter = ` <div class="row align-items-center justify-content-center"><div class="col-lg-6 col-md-6" style="flex: 0 0 auto; width: 50%;"><div class="invoice-terms"><h6>Notes:</h6><p class="mb-0">${result?.notes}</p></div><div class="invoice-terms"><h6>Terms and Conditions:</h6><p class="mb-0">${result?.terms}</p></div></div><div class="col-lg-6 col-md-6" style="flex: 0 0 auto; width: 50%;"><div class="invoice-total-card"><div class="invoice-total-box"><div class="invoice-total-inner"><p>Subtotal <span>${result?.currency} ${result?.subTotal.toFixed(2)} </span></p>`;
            result?.additional.forEach(element => {
              tableFooter += `<div class="ms-5 me-5"><p>${element?.chargeName} <span>${result?.currency} ${element?.chargeValue}}}</span></p></div>`;
            });
            tableFooter += `<p>Tax <span>${result?.currency} ${(result?.tax).toFixed(2)}</span></p></div><div class="invoice-total-footer"><h4>Total Amount <span>${result?.currency} ${result?.total.toFixed(2)}</span></h4></div></div></div></div></div>`;

            this.pageContent += tableFooter + invoiceFooter;
            myElement.innerHTML = this.pageContent;
            document.body.appendChild(myElement);
            currentHeight = myElement.offsetHeight - (totalPages * 2100);

            this.spinner.hide();
            setTimeout(() => {
              window.print();
            }, 1000);
          },
          error: (error) => {
            if (error?.status == 403) {
              this.toastr.error('Forbidden', 'Oops :(');
              this.spinner.hide();
              return;
            }
            var message = error.error.message;
            if (!message)
              message = "Unknown error occurred, Contact The System Administrator";

            this.toastr.error(message, 'Oops :(');
            this.spinner.hide();
          }
        });

        this.spinner.hide();
      },
      error: (error) => {
        if (error?.status == 403) {
          this.toastr.error('Forbidden', 'Oops :(');
          this.spinner.hide();
          return;
        }
        var message = error.error.message;
        if (!message)
          message = "Unknown error occurred, Contact The System Administrator";

        this.toastr.error(message, 'Oops :(');
        this.spinner.hide();
      }
    });
  }

  getMonthName(monthNumber: number) {
    if (this.translateService.currentLang == 'de')
      return this.germanMonths[monthNumber];
    else return this.englishMonths[monthNumber];
  }
}
