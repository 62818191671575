import { Injectable, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/shared/base.component';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseComponent {
    constructor(injector: Injector) {
        super(injector);
    }

    resetPassword(form: any) {
        return this.HttpClient.post<any>(this.pathUrl + `/Auth/ResetPassword`, form);
    }

    getAll() {
        return this.HttpClient.get<any>(this.pathUrl + `/Auth/Users`);
    }
}
