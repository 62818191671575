import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from "./error404/error404.component";
import { Error500Component } from "./error500/error500.component";
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { InvoicePrintComponent } from './all-modules/invoices/view-invoice/invoice-print/invoice-print.component';
const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import(`./all-modules/all-modules.module`).then(
        (m) => m.AllModulesModule
      ),
  },
  { path: "print-invoice/:id", component: InvoicePrintComponent },
  { path: "error-404", component: Error404Component },
  { path: "error-500", component: Error500Component },
  { path: "login", component: LoginComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "register", component: RegisterComponent },
  { path: '**', redirectTo: 'error-404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
