import { Injectable, Injector } from "@angular/core";
import { BaseComponent } from "../shared/base.component";

@Injectable({
    providedIn: 'root'
})
export class TimeSheetService extends BaseComponent {
    constructor(injector: Injector) {
        super(injector);
    }

    getTimeSheetList(fromDate: any, toDate: any, employeeIds: any, dataTableParameters: any) {
        return this.HttpClient.post<any>(this.pathUrl + `/EmployeeTimeSheets/GetTimeSheetList`, {
            fromDate,
            toDate,
            employeeIds,
            dataTableParameters
        });
    }

    getById(id: any) {
        return this.HttpClient.get<any>(this.pathUrl + `/EmployeeTimeSheets/${id}`);
    }

    post(employeeId: any, totalSeconds: any) {
        return this.HttpClient.post<any>(this.pathUrl + `/EmployeeTimeSheets`, {
            employeeId,
            totalSeconds,
        });
    }

    put(id: any, employeeId: any, totalSeconds: any) {
        return this.HttpClient.put<any>(this.pathUrl + `/EmployeeTimeSheets`, {
            id,
            employeeId,
            totalSeconds,
        });
    }

    delete(id: any) {
        return this.HttpClient.delete<any>(this.pathUrl + `/EmployeeTimeSheets/?id=${id}`);
    }
}
