import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
  constructor(private taostr: ToastrService) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    if (token == null) return false;

    let jwtHelper: JwtHelperService = new JwtHelperService();
    if (jwtHelper.isTokenExpired(token)) {
      this.logout();
      this.taostr.error(
        'Session Expired',
        'Your session expired, please login again'
      );
      return false;
    }

    return true;
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public setUserInformation(
    token: string,
    userId: string,
    employeeId: string,
    clientId: string,
    userName: string,
    imageName: string,
  ) {
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('employeeId', employeeId);
    localStorage.setItem('clientId', clientId);
    localStorage.setItem('userName', userName);
    localStorage.setItem('imageName', imageName);
  }

  isClient(): boolean {
    var clientId = localStorage.getItem('clientId');
    return clientId != null;
  }

  public getUserInformation() {
    return {
      userId: localStorage.getItem('userId'),
      employeeId: localStorage.getItem('employeeId'),
      clientId: localStorage.getItem('clientId'),
      userName: localStorage.getItem('userName'),
      imageName: localStorage.getItem('imageName'),
    };
  }

  public hasAccess(allowedClaims: any): boolean {
    if (allowedClaims == null) return true;
    var hasAccess = false;

    const token = this.getToken();

    if (token == null) return false;

    const tokenPayload: any = decode(token);
    var userClaims: any = null;
    allowedClaims
      .map((e: any) => e.claimType)
      .forEach((type: any) => {
        if (userClaims == null && tokenPayload[type] != null) {
          userClaims = tokenPayload[type];
        }
      });

    if (userClaims == null) return hasAccess;

    if (typeof userClaims === 'string') {
      userClaims = [userClaims];
    }

    allowedClaims
      .map((e: any) => e.claimValue)
      .forEach((value: any) => {
        if (userClaims.indexOf(value) != -1) {
          hasAccess = true;
        }
      });

    return hasAccess;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('employeeId');
    localStorage.removeItem('clientId');
    localStorage.removeItem('userName');
    localStorage.removeItem('imageName');
    localStorage.removeItem('lastActiveDate');
    localStorage.removeItem('time');
    localStorage.removeItem('timeSheetId');
  }
}
