<!-- Main Wrapper -->
<div class="main-wrapper">
    <div class="account-content">

        <div class="container">

            <!-- Account Logo -->
            <div class="account-logo">
                <a routerLink="/dashboard"><img src="assets/img/logo.png" alt="Dreamguy's Technologies"></a>
            </div>
            <!-- /Account Logo -->

            <div class="account-box">
                <div class="account-wrapper">
                    <h3 class="account-title">Register</h3>

                    <!-- Account Form -->
                    <form>
                        <div class="form-group">
                            <label>Email</label>
                            <input class="form-control" type="text"  [(ngModel)]="email">
                        </div>
                        <div class="form-group">
                            <label>User name</label>
                            <input class="form-control" type="text"  [(ngModel)]="userName">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" type="password" [(ngModel)]="password">
                        </div>
                        <div class="form-group text-center">
                            <button class="btn btn-primary account-btn" type="submit"
                                (click)="register()">Register</button>
                        </div>
                        <div class="account-footer">
                            <p>Already have an account? <a routerLink="/login">Login</a></p>
                        </div>
                    </form>
                    <!-- /Account Form -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Main Wrapper -->