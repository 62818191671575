import { Injectable, Injector } from "@angular/core";
import { BaseComponent } from "../shared/base.component";

@Injectable({
    providedIn: 'root'
})
export class invoicesService extends BaseComponent {
    constructor(injector: Injector) {
        super(injector);
    }

    getClientInvoices(fromDate: any, toDate: any, dataTableParameters) {
        return this.HttpClient.post<any>(this.pathUrl + `/Invoices/GetClientInvoices`, {
            fromDate, toDate, dataTableParameters
        });
    }

    getLeadInvoice(id: any) {
        return this.HttpClient.get<any>(this.pathUrl + `/Invoices/GetLeadInvoice/${id}`);
    }

    getInvoice(id: any) {
        return this.HttpClient.get<any>(this.pathUrl + `/Invoices/GetInvoice/${id}`);
    }

    getInvoices(fromDate: any, toDate: any, clients: any, workingFields: any, dataTableParameters) {
        return this.HttpClient.post<any>(this.pathUrl + `/Invoices/GetInvoicesList`, {
            fromDate, toDate, clients, workingFields, dataTableParameters
        });
    }

    saveInvoice(invoice: any) {
        return this.HttpClient.post<any>(this.pathUrl + `/Invoices/SaveInvoice`, invoice);
    }

    saveInvoiceByLeadInvoiceId(refId: any) {
        return this.HttpClient.post<any>(this.pathUrl + `/Invoices/SaveInvoiceByInvoiceLeadId/${refId}`, {});
    }

    sendInvoice(id: any) {
        return this.HttpClient.post<any>(this.pathUrl + `/Invoices/SendInvoice/${id}`, {});
    }

    payInvoice(id: any) {
        return this.HttpClient.put<any>(this.pathUrl + `/Invoices/PayInvoice/${id}`, {});
    }

    cancelInvoice(id: any) {
        return this.HttpClient.put<any>(this.pathUrl + `/Invoices/CancelInvoice/${id}`, {});
    }
}
