import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injector } from "@angular/core";
import { AuthService } from "../services/Auth/auth.service";

export abstract class BaseComponent {
    HttpClient: HttpClient;
    HttpHeader: HttpHeaders = new HttpHeaders;
    public pathUrl: string;
    public logoUrl: string;
    userProfileUrl: string;
    AuthService: AuthService;

    constructor(injector: Injector) {
        this.HttpClient = injector.get(HttpClient);
        this.pathUrl = "https://api.itnavigation.de";
        // this.pathUrl = "http://localhost:5286";
        this.userProfileUrl = "https://api.itnavigation.de/Images/GetImageByName/UserProfiles%5C";
        this.logoUrl = "https://api.itnavigation.de/Images/GetImageByName/logo%5C";
        this.AuthService = injector.get(AuthService);
    }

    englishMonths: string[] = ['0','January','February','March','April','May','June','July','August','September','October','November','December']
    germanMonths: string[] = ['0','January','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']    

    getIsoStringDate(date: any) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -1);
        return localISOTime;
    }

    groupBy<T, K>(list: T[], getKey: (item: T) => K) {
        const map = new Map<K, T[]>();
        list.forEach((item) => {
            const key = getKey(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return Array.from(map.values());
    }

    formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        let hour = '' + (d.getHours);
        let minutes = '' + (d.getMinutes);

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (hour.length < 2) day = '0' + day;
        if (minutes.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
}
