import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/Auth/auth.service';
import { LoginService } from '../services/auth/login.service';
import { BaseComponent } from '../shared/base.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent extends BaseComponent implements OnInit {

  constructor(injector: Injector,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private loginService: LoginService) {
    super(injector);
  }

  ngOnInit(): void { }

  email: string;
  userName: string;
  password: string;

  register() {
    this.loginService.register(this.email, this.userName, this.password).subscribe({
      next: (result: any) => {
        this.authService.setUserInformation(result?.token, result?.userId, result?.userName,
          result?.employeeId, result?.imageName, result?.position);

        this.router.navigate(['dashboard']);
        // this.spinner.hide();
      },
      error: (error) => {
        if (error?.status == 403) {
          this.toastr.error('Forbidden', 'Oops :(');
          // this.spinner.hide();
          return;
        }
        var message = error.error.message;
        if (!message)
          message = "Unknown error occurred, Contact The System Administrator";

        this.toastr.error(message, 'Oops :(');
        // this.spinner.hide();
      }
    });
  }

}
